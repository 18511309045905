<template>
	<div class="upload">
		<el-upload action="" :file-list="fileList" :on-change="handleChange" :on-remove="handleRemove" :on-preview="handlePreview" accept="image/*" :auto-upload="false" :multiple="multiple" :limit="limit" :disabled="disabled" list-type="picture">
			<el-button size="mini" type="primary" slot="trigger" :disabled="disabled || fileList.length >= limit">選擇圖片</el-button>
			<small slot="tip"><br>只能上傳圖片，且不能超過5MB</small>
		</el-upload>
		<el-dialog :visible="visible" :before-close="() => previewUrl = ''">
			<img :src="previewUrl" width="100%">
		</el-dialog>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	props: {
		"media-folder": String,
		model: {
			required: true
		},
		multiple: Boolean,
		limit: Number,
		disabled: Boolean
	},
	model: {
		prop: "model",
		event: "change"
	},
	data() {
		return {
			previewUrl: ""
		}
	},
	computed: {
		fileList: {
			get() {
				return this.model && Array.isArray(this.model) ? this.model : new Array;
			},
			set(val) {
				this.$emit("change", val);
			}
		},
		visible() {
			return this.previewUrl ? true : false;
		}
	},
	methods: {
		...mapActions("upload", ["_uploadImage", "_uploadMultiImage"]),
		async handleChange({ raw }, fileList) {
			const data = new FormData();
			data.append("file", raw);
			this.fileList.push(
				await this._uploadImage({
					data,
					folder: this.mediaFolder
						|| this.$route.path
							.split("/")
							.filter(item => item && item != "manager")
							.join("-"),
				})
			);
			this.$emit("change", this.fileList);
		},
		handleRemove({ }, fileList) {
			this.$emit("change", fileList);
		},
		handlePreview({ url } = new Object) {
			this.previewUrl = url;
		}
	}
}
</script>